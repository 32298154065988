import React, { ReactElement } from 'react';
import { useTranslations } from 'next-intl';
import Price from '@/components/Price';

import mergeClasses from '@/utils/Object/shallowMerge';
import type { AppliedTax } from '@/types/cart';
import type { IFlatData } from '@/components/PriceSummary/types';

interface ITaxSummaryProps {
    classes?: {
        [className: string]: string;
    };
    data: IFlatData['taxes'];
    isCheckout: boolean;
}

const TaxSummary = ({ classes: propClasses, data, isCheckout }: ITaxSummaryProps): ReactElement | null => {
    const classes = mergeClasses({}, propClasses);
    const t = useTranslations('taxSummary');

    // Don't render estimated taxes until an address has been provided which
    // causes the server to apply a tax value to the cart.
    if (!data?.length) {
        return null;
    }

    const taxLabel = isCheckout ? t('tax') : t('estimatedTax');

    const getEstimatedTax = (applied_taxes: AppliedTax[] = []): { currency: string; value: number } => {
        return {
            currency: applied_taxes[0].amount.currency,
            value: applied_taxes.reduce((acc, tax) => acc + tax.amount.value, 0),
        };
    };

    const tax = getEstimatedTax(data);

    return (
        <>
            <span className={classes.lineItemLabel}>{taxLabel}</span>
            <span className={classes.price}>
                <Price value={tax!.value} currencyCode={tax!.currency} />
            </span>
        </>
    );
};

TaxSummary.displayName = 'TaxSummary';

export default TaxSummary;
