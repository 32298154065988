import { gql } from '@apollo/client';
import { ProductListFragment } from '../modules/ProductList/api/productListFragments.gql';
import { PriceSummaryFragment } from '@/components/PriceSummary/api/priceSummaryFragments.gql';
import { AppliedCouponsFragment } from '@/components/PriceAdjustments/CouponCode/api/couponCodeFragments.gql';
import { AppliedStoreCreditFragment } from '@/components/AccountStoreCredit/api/appliedStoreCreditFragment.gql';

export const MiniCartFragment = gql`
    fragment MiniCartFragment on Cart {
        id
        total_quantity
        email
        errors {
            code
            message
        }
        selected_payment_method {
            code
            title
        }
        ...ProductListFragment
        ...AppliedCouponsFragment
        ...PriceSummaryFragment
        ...AppliedStoreCreditFragment
    }
    ${ProductListFragment}
    ${AppliedCouponsFragment}
    ${PriceSummaryFragment}
    ${AppliedStoreCreditFragment}
`;
