import { useEffect } from 'react';
import { usePathname } from 'next/navigation';
import { useQuery } from '@apollo/experimental-nextjs-app-support/ssr';
import { useAppContext, useCartContext, useUserContext } from '@/lib/context';
import { useIsTaxIncludedInPrice, type GetPriceWithOrWithoutTax } from '@/hooks/useIsTaxIncludedInPrice';
import { useResourceUrl } from '@/hooks/useResourceUrl';
import mergeOperations from '@/utils/Object/shallowMerge';
import DEFAULT_OPERATIONS from '../api/priceSummary.gql';
import { urls } from '@/constants';
import type { Discount, Money } from '@/types/product';
import type { ICartData, IFlatData, IUsePriceSummary, IUsePriceSummaryProps } from '../types';
import { useHhRouter } from '@/utils/useHhRouter';

export const getDiscountByLabel = (label: string, array?: Discount[]): Money | null => {
    if (!array?.length) {
        return null;
    }

    const result = array.find((item) => item.default_label === label);

    return result?.amount.value ? { ...result.amount } : null;
};

export const flattenData = (data: ICartData, getPriceWithOrWithoutTax: GetPriceWithOrWithoutTax): IFlatData => {
    if (!data) {
        return {};
    }

    const { prices, shipping_addresses } = data?.cart || {};
    const {
        applied_taxes,
        discount_breakdown,
        discounts,
        grand_total,
        subtotal_excluding_tax,
        subtotal_including_tax,
    } = prices || {};

    return {
        discounts: discount_breakdown,
        sale: getDiscountByLabel('Sale', discounts),
        shipping: shipping_addresses,
        subtotal: getPriceWithOrWithoutTax({
            priceExclTax: subtotal_excluding_tax,
            priceInclTax: subtotal_including_tax,
        }),
        taxes: applied_taxes,
        total: grand_total,
        totalSavings: getDiscountByLabel('Savings Total', discounts),
    };
};

export const usePriceSummary = ({
    data: externalData,
    operations: propOperations,
}: IUsePriceSummaryProps = {}): IUsePriceSummary => {
    const operations = mergeOperations(DEFAULT_OPERATIONS, propOperations);
    const { getPriceSummaryQuery, getProAccountStatus } = operations;
    const { getPriceWithOrWithoutTax } = useIsTaxIncludedInPrice();
    const [{ cartId }] = useCartContext();
    const [{ isSignedIn }] = useUserContext();
    const [
        {
            storeConfig: { deliverySettings },
        },
    ] = useAppContext();

    const resourceUrl = useResourceUrl();
    const router = useHhRouter();
    const pathname = usePathname();
    // We don't want to display "Estimated" or the "Proceed" button in checkout.
    const isCheckout = pathname === resourceUrl(urls.checkout);
    const isCheckoutSuccess = pathname === resourceUrl(urls.checkoutSuccess);

    const [{ storeConfig }] = useAppContext();
    const { isPriceIncludesTax } = storeConfig;

    const { data, error, loading } = useQuery(getPriceSummaryQuery, {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        skip: !cartId || !!externalData,
        variables: {
            cartId,
        },
    });

    const { data: proCustomerData } = useQuery(getProAccountStatus, {
        fetchPolicy: 'no-cache',
        skip: !isSignedIn,
    });

    const isProActive = proCustomerData?.customer?.pro_account?.discount_applicable;

    const errors = data?.cart?.errors || [];

    const hasItems = !!(externalData || data)?.cart?.items.length;

    useEffect(() => {
        if (errors?.length && isCheckout) {
            router.replace(resourceUrl(urls.cart));
        }
    }, [errors, isCheckout, resourceUrl, router]);

    return {
        deliverySettings,
        errors,
        flatData: flattenData(externalData || data, getPriceWithOrWithoutTax),
        hasError: !!error,
        hasItems,
        isCheckout: isCheckout || isCheckoutSuccess,
        isLoading: !!loading,
        isPriceIncludesTax,
        isProActive,
    };
};
