import React, { ReactElement } from 'react';
import { useTranslations } from 'next-intl';
import Price from '@/components/Price';
import Tooltip from '@/components/Tooltip';

import type { IFlatData } from '@/components/PriceSummary/types';
import mergeClasses from '@/utils/Object/shallowMerge';

interface IShippingSummaryProps {
    classes?: {
        [className: string]: string;
    };
    data: IFlatData['shipping'];
    isCheckout: boolean;
    isProActive: boolean;
}

const ShippingSummary = ({ classes, data, isCheckout, isProActive }: IShippingSummaryProps): ReactElement | null => {
    const tGlobal = useTranslations('global');
    const tShippingSummary = useTranslations('shippingSummary');
    const defaultClasses = mergeClasses({}, classes);
    const shippingMethod = data?.[0]?.selected_shipping_method;

    if (!shippingMethod) return null;

    const shipping = shippingMethod?.amount ?? 0;

    const shippingLabel = isCheckout ? tShippingSummary('shipping') : tShippingSummary('estimatedShipping');

    // For a value of "0", display "FREE".
    const price = shipping.value ? (
        <Price value={shipping.value} currencyCode={shipping.currency} />
    ) : (
        <span>{tGlobal('free')}</span>
    );

    const tooltipContent = tShippingSummary('tooltipContent');

    const shippingSummaryContent = (
        <>
            <span className={defaultClasses.lineItemLabel}>{shippingLabel}</span>
            <span className={defaultClasses.price}>{price}</span>
        </>
    );

    return isProActive ? (
        <div className={defaultClasses.tooltipContainer}>
            <Tooltip
                content={tooltipContent}
                classes={{
                    tooltipTrigger: defaultClasses.tooltipTrigger,
                }}
            >
                {shippingSummaryContent}
            </Tooltip>
        </div>
    ) : (
        shippingSummaryContent
    );
};

ShippingSummary.displayName = 'ShippingSummary';

export default ShippingSummary;
